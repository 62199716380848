body {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  background-image: url("background.png");
  background-repeat: no-repeat;
  background-position: center;
}

.outercontainer { 
  background-color: #f8f8f8; 
  max-width: 500px;
  display: inline-block;
  margin-top: 25px;
  border: 1px solid #ededed;
  text-align: left;
  border-radius: 20px;
}
  
.download { 
  background-color: #1798c1; 
  color: white; 
  padding: 14px 20px; 
  margin: 8px 0; 
  border: none; 
  cursor: pointer; 
  width: 100%; 
  border-radius: 20px;
  margin-top: 35px;
} 
  
.download:hover { 
  opacity: 0.8; 
}

.imgcontainer { 
  text-align: center; 
  margin: 24px 0 12px 0; 
} 
  
.container { 
  padding: 16px; 
}

h2 {
  text-align: center;
}

.error {
  text-align: center;
  background-color: #f40000; 
  color: white; 
  padding: 1px 20px; 
  border: none; 
  width: 91%; 
  border-radius: 20px;
  margin-top: 35px;
}

SELECT, INPUT[type="text"] {
  width: 205px;
  box-sizing: border-box;
  height: 100px;
}
SECTION {
  padding: 8px;
}
SECTION > DIV {
  float: left;
  padding: 4px;
}
SECTION > DIV + DIV {
  width: 40px;
  text-align: center;
}

OPTION {
  white-space: break-spaces;
}

.left-vals{
  margin-left: 5px;
}
